import { useRouteLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";
import { type loader as rootLoader } from "~/root";

export function useRequestInfo() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  invariant(data?.requestInfo, "No requestInfo found in root loader");

  return data.requestInfo;
}

export function useHints() {
  const requestInfo = useRequestInfo();
  return requestInfo.hints;
}
