import { useMatches } from "@remix-run/react";
import { useHints } from "./useHints";
import type { loader as rootLoader } from "~/root";
import type { SerializeFrom } from "@remix-run/node";

export function useTheme() {
  const hints = useHints();
  const matches = useMatches();
  const rootData = matches.find((match) => match.id === "root")
    ?.data as SerializeFrom<typeof rootLoader>;
  const userThemePreference = rootData?.userThemePreference;

  return userThemePreference ?? hints?.theme ?? "light";
}
